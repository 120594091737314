import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import PageHeading from '../../Components/PageHeading';
import { AppColors } from '../../Utils/helper/AppColors';
import MentionProgress from './Components/MentionProgress';
import CultService from '../../Services/CultService';
import toast from 'react-hot-toast';
import { HelperTexts } from '../../Utils/helper/HelperTexts';
import PageLoader from '../../Components/PageLoader';
import { useSelector } from 'react-redux';
import { extractNumberFromString } from '../../Utils/helper/commonUtils';
import { FiChevronDown } from 'react-icons/fi';
import _ from 'lodash';

export default function ActionableInsights() {
	const [loading, setLoading] = useState(false);
	const company = useSelector((state) => state?.app?.company);
	const [details, setDetails] = useState(null);

	const getDetails = async () => {
		setLoading(true);
		try {
			let res = await CultService.getActionalbleInsigts({ params: { companyId: company?._id } });
			let sorted = _.orderBy(res?.data?.data, ['reported'], ['desc']);
			setDetails(sorted);
		} catch (err) {
			toast.error(HelperTexts?.SOMETHING_WENT_WRONG);
		} finally {
			setLoading(false);
		}
	};

	const mentions = details
		?.flatMap((topic) => topic?.subTopic.flatMap((subtopic) => subtopic?.mentions))
		?.sort((a, b) => extractNumberFromString(b?.reported || 0) - extractNumberFromString(a?.reported || 0));

	useEffect(() => {
		if (company) getDetails();
	}, [company]);

	return (
		<Stack>
			{loading && <PageLoader />}
			<PageHeading title="Actionable Insights" subTitle="Insights into profound employee dynamics" />

			<Stack gap={2} mt={4}>
				{details?.map((insight, index) => (
					<Accordion key={index}>
						<AccordionSummary expandIcon={<FiChevronDown color={AppColors.DARK_GREY} size={20} />}>
							<Stack flex={1} mr={8} direction="row" justifyContent="space-between" alignItems="center">
								<Typography variant="subtitle1">{insight.name}</Typography>
								<Typography variant="subtitle2">
									Reported by <span style={{ fontWeight: 600 }}>{insight?.reportedBy || 0}</span>
								</Typography>
							</Stack>
						</AccordionSummary>
						<AccordionDetails>
							<Stack gap={2}>
								{insight?.subTopic?.map((sub) => (
									<TableContainer>
										<Table>
											<TableHead>
												<TableCell colSpan={3}>
													<Typography variant="subtitle1">{sub?.name}</Typography>
												</TableCell>
											</TableHead>
											<TableHead
												sx={{ '& .MuiTableCell-root': { fontSize: 12, color: AppColors.TEXT_TERTIARY, fontWeight: 500 } }}
											>
												<TableCell width="44%">Insights</TableCell>
												<TableCell width="44%">Recommended Action</TableCell>
												<TableCell>Mentions</TableCell>
											</TableHead>

											<TableBody>
												{sub?.mentions?.map((mention) => (
													<TableRow>
														<TableCell>
															<Typography variant="subtitle2">{mention?.impact}</Typography>
														</TableCell>
														<TableCell>
															<Typography variant="subtitle2">{mention?.solution}</Typography>
														</TableCell>
														<TableCell>
															<MentionProgress hidePercentageSign hideBar value={parseInt(mention?.reported?.replace('%', ''))} />
														</TableCell>
													</TableRow>
												))}
											</TableBody>
										</Table>
									</TableContainer>
								))}
							</Stack>
						</AccordionDetails>
					</Accordion>
				))}
			</Stack>
		</Stack>
	);
}
