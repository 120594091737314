import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import PageLoader from '../../Components/PageLoader';
import PageHeading from '../../Components/PageHeading';
import SelectCampaign from '../../Components/SelectCampaign';
import CampaignEmptyState from '../../Components/CampaignEmptyState';
import CultService from '../../Services/CultService';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { HelperTexts } from '../../Utils/helper/HelperTexts';
import { FiChevronDown } from 'react-icons/fi';
import { AppColors } from '../../Utils/helper/AppColors';
import MentionProgress from './Components/MentionProgress';

export default function CampaignInsights() {
	const [loading, setLoading] = useState(false);
	const [filterQuery, setFilterQuery] = useState(null);
	const company = useSelector((state) => state?.app?.company);
	const [insights, setInsights] = useState([]);

	const handleSelectCampaign = (i, option) => {
		setFilterQuery((prev) => ({ ...prev, campaigns: [option?.item] }));
	};

	const getCampaignInsights = async () => {
		setLoading(true);
		try {
			let res = await CultService.getCampaignInsights({
				params: {
					companyId: company?._id,
					campaigns: filterQuery?.campaigns?.map((i) => i._id),
				},
			});
			setInsights(res.data.data);
		} catch (err) {
			toast.error(HelperTexts?.SOMETHING_WENT_WRONG);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (company) {
			if (filterQuery?.campaigns?.length > 0) {
				getCampaignInsights();
			}
		}
	}, [company, filterQuery]);

	return (
		<Stack gap={3}>
			{loading && <PageLoader />}
			<PageHeading title="Reporting" subTitle="Track, manage and forecast your employee mindset" />

			<Typography variant="h3">Campaign Insights</Typography>

			<Stack gap={2}>
				<SelectCampaign handleSelectCampaign={handleSelectCampaign} />
				{filterQuery?.campaigns?.length > 0 ? (
					insights?.map((insight, index) => (
						<Accordion key={index}>
							<AccordionSummary expandIcon={<FiChevronDown color={AppColors.DARK_GREY} size={20} />}>
								<Stack flex={1} mr={8} direction="row" justifyContent="space-between" alignItems="center">
									<Typography variant="subtitle1">{insight.name}</Typography>
									<Typography variant="subtitle2">
										Reported by <span style={{ fontWeight: 600 }}>{insight?.reportedBy || 0}</span>
									</Typography>
								</Stack>
							</AccordionSummary>
							<AccordionDetails>
								<Stack gap={2}>
									{insight?.subTopic?.map((sub) => (
										<TableContainer>
											<Table>
												<TableHead>
													<TableCell colSpan={3}>
														<Typography variant="subtitle1">{sub?.name}</Typography>
													</TableCell>
												</TableHead>
												<TableHead
													sx={{ '& .MuiTableCell-root': { fontSize: 12, color: AppColors.TEXT_TERTIARY, fontWeight: 500 } }}
												>
													<TableCell width="44%">Insights</TableCell>
													<TableCell width="44%">Recommended Action</TableCell>
													<TableCell>Mentions</TableCell>
												</TableHead>

												<TableBody>
													{sub?.mentions?.map((mention) => (
														<TableRow>
															<TableCell>
																<Typography variant="subtitle2">{mention?.text}</Typography>
															</TableCell>
															<TableCell>
																<Typography variant="subtitle2">{mention?.solution}</Typography>
															</TableCell>
															<TableCell>
																<MentionProgress value={parseInt(mention?.reported?.replace('%', ''))} />
															</TableCell>
														</TableRow>
													))}
												</TableBody>
											</Table>
										</TableContainer>
									))}
								</Stack>
							</AccordionDetails>
						</Accordion>
					))
				) : (
					<CampaignEmptyState text="Select a campaign to view campaign insights." />
				)}
			</Stack>
		</Stack>
	);
}
