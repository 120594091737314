import axios from 'axios';
import { PATH_NAME } from '../Routes/Pathname';
import { clearSession, getAccessToken } from '../Utils/storage/user';
import toast from 'react-hot-toast';
import { HelperTexts } from '../Utils/helper/HelperTexts';

export const axiosInstance = axios.create({
	baseURL: process.env.REACT_APP_URL,
	// timeout: 60000,
	showLoader: false,
});

axiosInstance.interceptors.request.use(
	async (config) => {
		const accessToken = getAccessToken();
		if (accessToken) {
			config.headers.Authorization = `Bearer ${accessToken}`;
		}

		return config;
	},
	(error) => {
		return Promise.reject(error);
	},
);

axiosInstance.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		if (error?.response?.status === 401) {
			toast.error(error?.response?.data?.message || HelperTexts.UNAUTHORISED);
		}
		return Promise.reject(error);
	},
);
