import { LinearProgress, Stack, Typography } from '@mui/material';
import React from 'react';
import { AppColors } from '../../../Utils/helper/AppColors';

export default function MentionProgress({ value, hidePercentageSign, hideBar }) {
	return (
		<Stack direction="row" alignItems="center" gap={1.5}>
			{!hideBar && (
				<LinearProgress
					sx={{
						height: 8,
						borderRadius: 8,
						width: 47,
						'.MuiLinearProgress-bar': { borderRadius: 10 },
						'&.MuiLinearProgress-root': { bgcolor: AppColors.BORDER_GREY },
					}}
					variant="determinate"
					value={value}
				/>
			)}
			<Typography variant="subtitle1" fontSize={12} color={AppColors.TEXT_SECONDARY}>
				{value}
				{!hidePercentageSign && <span>%</span>}
			</Typography>
		</Stack>
	);
}
