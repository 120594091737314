export const HelperTexts = {
	SOMETHING_WENT_WRONG: 'Something went wrong',
	UPDATED_SUCCESSFULLY: 'Updated successfully',
	CREATED_SUCCESSFULLY: 'Created successfully',
	INVALID_INPUT: 'Invalid Input',
	CONTACT_US: 'Contact us at contact@cultureos.company',
	COMING_SOON: 'Coming soon',
	WORK_IN_PROGRESS: 'Work is progress',
	UNAUTHORISED: 'Unauthorized',
};
